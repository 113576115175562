const apis = {
  dev: {
    typ: "https://dev.tms.aurobica.com",
    /*   typ: "https://tms.typ.delivery", */
    a2u: "https://test.a2u.ops.typ.delivery",
    a2ufr: "https://test.a2u.ops.typ.delivery",
  },
  prod: {
    typ: "https://tms.typ.delivery",
    a2u: "https://a2u.ops.typ.delivery",
    a2ufr: "https://a2u-fr.ops.typ.delivery",
  },
};

export const api =
  apis[process.env.REACT_APP_STAGE][process.env.REACT_APP_TARGET];

export const baseUrl = "/api/v1";

export const baseAddress = api + baseUrl;
